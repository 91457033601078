import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { MDX } from "../components/MDX"
import Layout from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Dates } from "../components/Dates";
import { TableOfContents } from "../components/TableOfContents";
//import { NextPrev } from "../components/NextPrev";
import { RecommendLinks } from "../components/RecommendLinks";
import { CategoryNewArticleLinks } from "../components/CategoryNewArticleLinks"
import { AdSenseWAutoH90 } from "../components/AdSenseWAutoH90"
import { AdSense } from "../components/AdSense"
import { A8Square } from "../components/A8Square";
import { Posts } from "../components/Posts"
import { Links } from "../components/Links"
import { Box } from "@material-ui/core";
import { shuffle } from "../utils/Shuffle";
import theme, { breakpoints } from "../style/theme";

//import {
//  FacebookShareButton,
//  LineShareButton,
//  LinkedinShareButton,
//  PocketShareButton,
//  TwitterShareButton,
//} from "react-share";

//import {
//  FacebookIcon,
//  LineIcon,
//  LinkedinIcon,
//  PocketIcon,
//  TwitterIcon,
//} from "react-share";

const Title = styled.h1`
  margin: 0;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.167;
  letter-spacing: .065em;
  word-wrap: break-word;
  @media screen and (max-width: ${breakpoints.md}px) {
    font-size: 1.5rem;
  }
`;

const Article = styled.article`
  width: 100%;
  margin: 1rem 0;
  line-height: 2.0;
  font-size: 1.0rem;
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 0.9rem;
  }
  & blockquote {
    border-left: 3px solid #303032;
    margin-left: -2rem;
    padding-left: 1.5rem;
    font-style: italic;
  }
`

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 1.25rem;
  width: 100%;
  margin: 1.0rem 0;
`;

const Tag = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0;
  color: ${theme.palette.primary.main}!important;
  background-color: transparent;
  outline: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none!important;
  letter-spacing: .13em;
  transition: .1s;
  &:hover {
    ${theme.palette.rainbow}
  }
`

const Tags = ({ tags }) => {
  return (
    <TagsWrapper>
      {tags.map(tag => {
        return (
          <Tag key={`${tag}`} to={`/tag/${tag}/page/1`}>#{tag}</Tag>
        );
      })}
    </TagsWrapper>
  );
};

export default function PostTemplate({ data, pageContext }) {
  const { post, nextPost, prevPost } = data;
  const {
    frontmatter: { featuredImage, title, category, description, createdDate, updatedDate, tags },
    tableOfContents,
    body
  } = post;

  //const categoryPosts = shuffle(data.posts.edges.filter(edge => edge.node.frontmatter.category === category));
  const posts = shuffle(data.posts.edges.filter(edge => edge.node.frontmatter.tags.find(tag => tags.includes(tag))));

  const pathPrefix = data.site.siteMetadata.templates.posts.pathPrefix;
  const pathPrefixCategory = data.site.siteMetadata.templates.posts.filters.category.pathPrefixCategory;

  const { postPath, previousPath, nextPath } = pageContext;

  useEffect(() => {
    if (document && window) {
      document.querySelectorAll(`a[href^="#"]`).forEach(a => {
        a.addEventListener('click', e => {
          const header = document.getElementById('header');
          const content = document.getElementById(decodeURI(e.target.getAttribute("href").slice(1)));

          window.scrollBy(0, content.getBoundingClientRect().top - header.clientHeight);
          e.preventDefault();
          return true;
        })
      })
    }
  }, [tableOfContents])

  return (
    <Layout
      title={title}
      description={description}
      image={featuredImage.childImageSharp.fluid.src}
    >
      <Breadcrumbs
        links={[
          {
            url: `/${pathPrefix}/page/1`,
            title: 'BLOG',
          },
          {
            url: `/${pathPrefixCategory}/${category}/page/1`,
            title: category,
          },
          //{
          //  url: null,
          //  title: `${title}`,
          //}
        ]}
      />
      <Article>
        <Box marginBottom={1}>
          <Title>{title}</Title>
          <Tags tags={tags} />
          <Dates createdDate={createdDate} updatedDate={updatedDate} />
        </Box>
        <Img
          alt={title}
          placeholderClassName='webfeedsFeaturedVisual'
          fluid={featuredImage.childImageSharp.fluid}
          style={{ borderRadius: 2 }}
        />
        <TableOfContents
          items={tableOfContents.items}
          depth={1}
          backgroundColor={theme.palette.primary.main}
          fontColor={theme.palette.border}
          linkColor={theme.palette.blue}
          breakpoint={breakpoints.sm}
        />
        <MDX title={title} body={body} category={category} />
      </Article>
      {/* <A8Square category={category} /> */}
      <AdSenseWAutoH90 />
      <Links title={`関連記事`} en={`Articles`} posts={posts} />
      <CategoryNewArticleLinks category={category} limit={5} isAdSense={true} />
      <RecommendLinks category={category} />
      {/* Share Buttons */}
      {
      //<Box marginTop={2} marginBottom={2}>
      //  <FacebookShareButton url={postPath} className="mr-4">
      //    <FacebookIcon size={40} round />
      //  </FacebookShareButton>
      //  <TwitterShareButton url={postPath} className="mr-4">
      //    <TwitterIcon size={40} round />
      //  </TwitterShareButton>
      //  <LinkedinShareButton url={postPath} className="mr-4">
      //    <LinkedinIcon size={40} round />
      //  </LinkedinShareButton>
      //  <LineShareButton url={postPath} className="mr-4">
      //    <LineIcon size={40} round />
      //  </LineShareButton>
      //  <PocketShareButton url={postPath} className="mr-4">
      //    <PocketIcon size={40} round />
      //  </PocketShareButton>
      //</Box>
      }

      {
      //<NextPrev prevPath={previousPath} prevPost={prevPost} nextPath={nextPath} nextPost={nextPost} />
      //<Posts title={`${category}`} en={`Category`} posts={categoryPosts} />
      }
    </Layout>
  );
}

export const pageQuery = graphql`
  query($postId: String!, $previousPostId: String, $nextPostId: String) {
    post: mdx(frontmatter: { id: { eq: $postId } }) {
      tableOfContents
      body
      frontmatter {
        id
        title
        category
        description
        createdDate
        updatedDate
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        templates {
          posts {
            pathPrefix
            filters {
              category {
                pathPrefixCategory
              }
              tag {
                pathPrefixTag
                totalPosts
              }
            }
          }
        }
      }
    }
    posts: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/" }
        frontmatter: {
          id: { ne: $postId }
        }
      }
      sort: { order: DESC, fields: [fileAbsolutePath] }
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            id
            title
            category
            description
            createdDate
            updatedDate
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    prevPost: mdx(frontmatter: { id: { eq: $previousPostId } }) {
      body
      frontmatter {
        id
        title
        category
        description
        createdDate
        updatedDate
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    nextPost: mdx(frontmatter: { id: { eq: $nextPostId } }) {
      body
      frontmatter {
        id
        title
        category
        description
        createdDate
        updatedDate
        tags
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
