import React, { useEffect } from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin: 1rem auto;
  padding: 1rem 0;
  box-sizing: border-box;
`

const DummyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin: 2rem auto;
  outline: 1px dotted #333;
  text-align: center;
  box-sizing: border-box;
`

export const AdSenseWAutoH90 = (props) => {

  const path =  typeof window === `undefined` ? '' : window.location.href;

  useEffect(() => {
    if (window && process.env.NODE_ENV === "production") {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
    }
  }, [path]);

  if (process.env.NODE_ENV === "production") {
    return (
      <Wrapper>
        <ins class="adsbygoogle"
          style={{display: 'inline-block', width: '100%', height: '90px'}}
          data-full-width-responsive="true"
          data-ad-client="ca-pub-7360388694996375"
          data-ad-slot="2931773411"></ins>
      </Wrapper>
    )
  } else {
    return (
      <DummyWrapper>Ad</DummyWrapper>
    )
  }

};
