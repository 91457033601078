import React, { useState, useEffect } from "react";
import styled from "styled-components"
import { SubTitle } from "./SubTitle";
import { AmazonLinks } from "../utils/AmazonLinks";
import { A8Links } from '../utils/A8Links';
import { shuffle } from "../utils/Shuffle";
import Link from './Link';

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
`

const LinkWrapper = styled.div`
  display: inline-block;
  width: 100%;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.0rem;
  width: 100%;
  a {
    position: relative;
    display: inline-block;
    margin: 0.5rem 0;
    padding-left: calc(16px + 0.5rem);
    box-sizing: border-box;
    color: #666;
    text-decoration: none;
    transition: .1s;
    &:before,
    &:after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      margin: 0;
      width: 16px;
      height: 16px;
      border-style: solid;
      border-color: #666;
      transition: .1s;
    }
    &:before {
      top: .1rem;
      left: 0;
      border-width: 1px;
    }
    &:after {
      top: calc(.1rem + 3px);
      left: 3px;
      border-width: 0 1px 1px 0;
      border-color: #00ccff;
    }
    &:hover {
      background: -webkit-linear-gradient(45deg, #54d0ff, #9f92ff 20%, #ff7689 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &:before {
        border-color: #00ccff;
      }
    }
  }
`

export const RecommendLinks = ({category}) => {

  const POST_COUNT = -4;

  const [books, setBooks] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    setBooks(shuffle(AmazonLinks.filter(bd => bd.categories.includes(category))).slice(POST_COUNT));

    let l =
      category
        ?
        shuffle(A8Links.filter(link => link.categories.includes(category) && link.text)).slice(POST_COUNT)
        :
        shuffle(A8Links.filter(link => link.sUrl && link.text)).slice(POST_COUNT);

    if (!l) {
      l = shuffle(A8Links.filter(link => link.sUrl && link.text)).slice(POST_COUNT);
    }

    if (l) {
      //setLinks(l);
    }
  }, []);

  if (books.length === 0 && links.length === 0) {
    return <></>;
  }

  return (
    <Wrapper>
      {
        category === "ガジェット" ?
        <SubTitle en={`Items`}>PR</SubTitle>
        :
        <SubTitle en={`Books`}>PR</SubTitle>
      }
      <LinksWrapper className="recommended-links">
        {
          books.map((book, i) => {
            return (
              <Link key={i} href={book.url}>{book.title}</Link>
            )
          })
        }
        {
          links.map((link, i) => {
            return (
              <LinkWrapper key={i} dangerouslySetInnerHTML={{ __html: link.text }} />
            )
          })
        }
      </LinksWrapper>
    </Wrapper>
  );
}
