import React, { useEffect } from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
`

const DummyWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 4rem 0;
  outline: 1px dotted #333;
  text-align: center;
  box-sizing: border-box;
`

export const AdSense = (props) => {

  const path =  typeof window === `undefined` ? '' : window.location.href;

  useEffect(() => {
    if (window && process.env.NODE_ENV === "production") {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
    }
  }, [path]);

  if (process.env.NODE_ENV === "production") {
    return (
      <Wrapper>
        <ins
          //className="adsbygoogle"
          //style={{display: 'block'}}
          //data-ad-client="ca-pub-7360388694996375"
          //data-ad-slot="1854432175"
          //data-ad-format="auto"
          //data-full-width-responsive="true"
          className="adsbygoogle"
          style={{display: 'inline-block', width: '100%', height: '242px'}}
          data-ad-client="ca-pub-7360388694996375"
          data-ad-slot="7977979327"
        />
      </Wrapper>
    )
  } else {
    return (
      <DummyWrapper>Ad</DummyWrapper>
    )
  }

};
