import React from "react";
import styled from "styled-components"
import { SubTitle } from "./SubTitle";
import Link from './Link';

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.0rem;
  width: 100%;
  a {
    position: relative;
    display: inline-block;
    margin: 0.5rem 0;
    padding-left: calc(16px + 0.5rem);
    box-sizing: border-box;
    color: #666;
    text-decoration: none;
    transition: .1s;
    &:before,
    &:after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      margin: 0;
      width: 16px;
      height: 16px;
      border-style: solid;
      border-color: #666;
      transition: .1s;
    }
    &:before {
      top: .1rem;
      left: 0;
      border-width: 1px;
    }
    &:after {
      top: calc(.1rem + 3px);
      left: 3px;
      border-width: 0 1px 1px 0;
      border-color: #00ccff;
    }
    &:hover {
      background: -webkit-linear-gradient(45deg, #54d0ff, #9f92ff 20%, #ff7689 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &:before {
        border-color: #00ccff;
      }
    }
  }
`

export const Links = ({title, en, posts, limit=5}) => {
  return (
    <Wrapper>
      <SubTitle en={en}>{title}</SubTitle>
      <LinksWrapper>
        {
          posts.slice(-1 * limit).map((a, i) => {
            return (
              <Link key={i} to={`/posts/${a.node.frontmatter.id}`}>{a.node.frontmatter.title}</Link>
            )
          })
        }
      </LinksWrapper>
    </Wrapper>
  );
}
