import React from "react";
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby";
import { SubTitle } from "./SubTitle";
import { ButtonLink } from './ButtonLink';
import Link from './Link';
import { categoryEn } from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
`

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.0rem;
  width: 100%;
  a {
    position: relative;
    display: inline-block;
    margin: 0.5rem 0;
    padding-left: calc(16px + 0.5rem);
    box-sizing: border-box;
    color: #666;
    text-decoration: none;
    transition: .1s;
    &:before,
    &:after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      margin: 0;
      width: 16px;
      height: 16px;
      border-style: solid;
      border-color: #666;
      transition: .1s;
    }
    &:before {
      top: .1rem;
      left: 0;
      border-width: 1px;
    }
    &:after {
      top: calc(.1rem + 3px);
      left: 3px;
      border-width: 0 1px 1px 0;
      border-color: #00ccff;
    }
    &:hover {
      background: -webkit-linear-gradient(45deg, #54d0ff, #9f92ff 20%, #ff7689 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &:before {
        border-color: #00ccff;
      }
    }
  }
`

export const CategoryNewArticleLinks = ({ category, limit = 3, isAdSense = false }) => {

  const { site: { siteMetadata }, allMdx: { edges } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            templates {
              posts {
                pathPrefix
                filters {
                  category {
                    pathPrefixCategory
                  }
                }
              }
            }
          }
        }
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/posts/" } }
          sort: { order: DESC, fields: [fileAbsolutePath] }
        ) {
          edges {
            node {
              excerpt(pruneLength: 50, truncate: true)
              fileAbsolutePath
              frontmatter {
                id
                title
                category
                description
                tags
                createdDate
                updatedDate
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const articles = edges.filter(edge => edge.node.frontmatter.category === category).slice(0, limit);

  if (articles.length === 0) {
    return(<></>);
  }

  return (
    <Wrapper>
      <SubTitle en={categoryEn[category]}>{category}</SubTitle>
      <LinksWrapper>
        {
          articles.map((a, i) => {
            return (
              <Link key={i} to={`/posts/${a.node.frontmatter.id}`}>{a.node.frontmatter.title}</Link>
            )
          })
        }
      </LinksWrapper>
      <ButtonLink
        to={`/${siteMetadata.templates.posts.filters.category.pathPrefixCategory}/${category}/page/1`}
      >
        View More
      </ButtonLink>
    </Wrapper>
  );
}
