import React from "react";
import styled from "styled-components";
import theme, { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4.0rem 0rem;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.fontColor};
`

const Title = styled.h2`
  width: 100%;
  text-align: center;
`

const List = styled.ul`
  box-sizing: border-box;
  list-style-type: none;
  padding-inline-start: 1rem;
  a {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 0.25rem;
    box-sizing: border-box;
    text-decoration: none;
    color: ${props => props.fontColor};
    border: 1px solid ${props => props.backgroundColor};
    font-size: 1rem;
    transition: .1s;
    @media screen and (max-width: ${props => props.breakpoint}px) {
      font-size: 0.8rem;
    }
    &:hover {
      ${theme.palette.rainbow}
      //border: 1px solid ${props => props.linkColor};
      //&::before {
      //  position: absolute;
      //  content: "▷";
      //  margin: auto;
      //  transition: .1s;
      //  left: -1.5rem;
      //  width: 100%;
      //  border-radius: 0;
      //  color: ${props => props.linkColor};
      //}
    }
  }
`

const TableOfContent = ({items, depth, fontColor, backgroundColor, linkColor, breakpoint, index=null}) => {

  // 2階層までとする
  if (depth >= 3) {
    return (<></>);
  }

  return (
    <List fontColor={fontColor} backgroundColor={backgroundColor} linkColor={linkColor} breakpoint={breakpoint}>
    {
      items.map((item, i) => (
        <li key={i}>
          {<a href={item.url}>{index ? `${index}.` : ""}{i+1}. {item.title}</a>}
          { item.items && <TableOfContent
            items={item.items}
            depth={depth + 1}
            fontColor={fontColor}
            backgroundColor={backgroundColor}
            linkColor={linkColor}
            breakpoint={breakpoint}
            index={i+1}
          />}
        </li>
      ))
    }
    </List>
  )
};

export const TableOfContents = ({ items, depth, breakpoint, backgroundColor="#f00", fontColor="#000", linkColor="#000"}) => {

  return (
    <Wrapper backgroundColor={backgroundColor} fontColor={fontColor}>
      <Title>目次</Title>
      <TableOfContent
        items={items}
        depth={depth}
        fontColor={fontColor}
        backgroundColor={backgroundColor}
        linkColor={linkColor}
        breakpoint={breakpoint}
      />
    </Wrapper>
  );
};
