import React from "react";
import styled from "styled-components"
import { SubTitle } from "./SubTitle";
import { ArticlePhotoRowLinks } from "./ArticlePhotoRowLinks";
import { MarqueeArticles } from "../components/MarqueeArticles";
import { ArticlePhotoRowAffiliateLink } from "./ArticlePhotoRowAffiliateLink";
import theme, { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
`

const PostsWrapper = styled.div`
  width: 100%;
  margin: 0;
`

const LIMIT = 3;

export function Posts ({title, en, posts}) {
  return (
    <Wrapper>
      <SubTitle en={en}>{title}</SubTitle>
      <ArticlePhotoRowLinks
        edges={posts.slice(-1 * LIMIT)}
        fontColor={theme.palette.white}
        backgroundColor={theme.palette.primary.main}
        breakpoint={breakpoints.sm}
        isAdSense={false}
      />
      <ArticlePhotoRowAffiliateLink
        fontColor={theme.palette.white}
        backgroundColor={theme.palette.primary.main}
        breakpoint={breakpoints.sm}
      />
      {
      //<MarqueeArticles edges={posts} />
      }
    </Wrapper>
  );
}
