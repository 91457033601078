import React from "react";
import styled from "styled-components"
import { PostImageLink } from "./PostImageLink";
import Marquee from "react-fast-marquee";
import { shuffle } from "../utils/Shuffle";

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
`

const MarqueeWrapper = styled.div`
  margin: .15rem 0;
`

export const MarqueeArticles = ({ edges, speed=20 }) => {

  const articles = shuffle(edges);

  if (articles.length === 0) {
    return(<></>);
  }

  return (
    <Wrapper>
      <MarqueeWrapper>
        <Marquee speed={speed} gradient={false} pauseOnHover={false}>
          {
            articles.map(article => {
              return (
                <PostImageLink id={article.node.frontmatter.id} width={200} mx={"2rem"} />
              )
            })
          }
        </Marquee>
      </MarqueeWrapper>
    </Wrapper>
  );
}
